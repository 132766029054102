import { AllMetricsResponse, Metric } from '@/shared/types/metrics';

export const mockMetrics: Metric[] = Array.from({ length: 29 }, (_, i) => ({
  combinedReference: `combinedReference-${i + 1}`,
  name: `Metric ${i + 1}`,
  unit: {
    reference: `unit-reference-${i + 1}`,
    name: 'quantity',
    current: i,
    goal: i % 2 === 0 ? i + 1 : i,
  },
  reference: `reference-${i + 1}`,
  category: `category-${i + 1}`,
}));

export const mockAllMetricsResponse: AllMetricsResponse = {
  rotationDuration: 10,
  metrics: mockMetrics,
};
